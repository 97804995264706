import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DeviceProfileService } from '../service/device-profile.service';
import { UtilService } from '../util/utility.services';
import { CommonURL } from '../commons/commons';
import { RequestOptions, Headers } from '@angular/http';
import { PostDeviceDetails } from '../commons/classes/post_profile_detail';
import { Router } from '@angular/router';
// import * as $ from 'jquery'

declare var $; // declared a var just for modal

@Component({
  selector: 'app-device-new-profile-procedure',
  templateUrl: './device-new-profile-procedure.component.html',
  styleUrls: ['./device-new-profile-procedure.component.scss']
})
export class DeviceNewProfileProcedureComponent implements OnInit {
  // Viewchild is used for modal popup message when getting response from the server
  @ViewChild('successmodal') successmodal: ElementRef;
  @ViewChild('failmodal') failmodal: ElementRef;
  default = 30;
  // showModal: string ;
  userObject: any = {};
  authDetails;
  apkDetails;
  deviceDetails$;

   // Getting profile name exitpasscode & clearpasscode data from device-new-profile with sharedservice using subject.
  newDeviceProfile: any = [];
  constructor(private deviceProfileService: DeviceProfileService, private util: UtilService, private router: Router ) { }

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

      if (this.userObject == null || this.userObject === undefined) {
        this.router.navigate(['/login']);
      } else {
        this.authDetails = {
          id: this.userObject.id,
          token: this.userObject.token
        };
    // subscribing to the shared service
    this.deviceProfileService.currentData.subscribe(response => this.newDeviceProfile = response);
   // console.log('data ', this.newDeviceProfile);
   this.getApkDetails();
      }
  }

  getApkDetails() {
    const headers = new Headers({
      'authorization': this.authDetails.token,
      'user-id': this.authDetails.id,
       });
    const options = new RequestOptions({ headers: headers});

    const body = JSON.stringify(null);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_APK, body, options).subscribe(
      (response) => {
        console.log(response);
        this.apkDetails = response;
      }, (error) => {
        console.log(error);
      }
    );
  }


  save(details) {

    const detailInfo = details;
    console.log(detailInfo);
    const headers = new Headers({
      'authorization': this.authDetails.token,
        'user-id': this.authDetails.id,
      }
      );
    const options = new RequestOptions({ headers: headers });

    const deviceDetailClass = PostDeviceDetails
                            .create(this.newDeviceProfile.profile_name,
                                    this.newDeviceProfile.passcode,
                                    detailInfo.apk_id,
                                    this.newDeviceProfile.clear_passcode,
                                    detailInfo.nav_status,
                                    detailInfo.taglock_status,
                                    detailInfo.default_apk_call);

    // console.log(deviceDetailClass);
    const body = JSON.stringify(deviceDetailClass);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_PROFILE_PROCEDURE, body, options)
    .subscribe(
      (response) => {
        // if(this.newDeviceProfile.length !== 0)
        console.log('Response: ', response);
        // this.showModal = "Success";
        $(this.successmodal.nativeElement).modal('show');
      }, (error) => {
        console.log(error);
        // this.showModal = false;
        // this.showModal = "There was an issue"
        $(this.failmodal.nativeElement).modal('show');
      }
    );
      // this.router.navigate(["/deviceProfiles"]);

  }
}
