export class PostDeviceDetails{
  profile_name: string;
  passcode: number;
  default_package_name: string;
  clear_passcode: number;
  nav_status: boolean;
  taglock_status: boolean;
  default_apk_call: number;
  apk_id: number;

  static create(profile_name, passcode, apk_id, clear_passcode,nav_status,taglock_status, default_apk_call){
    return new PostDeviceDetails(profile_name, passcode, apk_id, clear_passcode,nav_status,taglock_status, default_apk_call);
  }

  constructor(profile_name, passcode, apk_id, clear_passcode,nav_status,taglock_status, default_apk_call){
    this.profile_name = profile_name;
    this.passcode = passcode;
    this.apk_id = apk_id;
    this.clear_passcode = clear_passcode;
    this.nav_status = nav_status;
    this.taglock_status = taglock_status;
    this.default_apk_call = default_apk_call;
  }
}
