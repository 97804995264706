import { CommonModule } from '@angular/common';
import {UtilService} from './util/utility.services';
import {SharedService} from './util/shared-service.services';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';

import { AppRoutingModule } from './app-routing.module';

import { AuthGuard } from './shared';
import { DeviceService } from './service/device.service';
import { DeviceProfileService } from './service/device-profile.service';
import { DeviceGroupService } from './service/device-group.service';
import { LoadingSpinnerService } from './service/loading-spinner.service';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PapaParseModule } from 'ngx-papaparse';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { AppComponent } from './app.component';
import { ModalComponent } from './modal/modal.component';
import { DeviceNewProfileProcedureComponent } from './device-new-profile-procedure/device-new-profile-procedure.component';
import { FormsModule } from '@angular/forms';
import { DeviceNewGroupProcedureComponent } from './device-new-group-procedure/device-new-group-procedure.component';
import { LoginComponent } from './login/login.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        LanguageTranslationModule,
        AppRoutingModule,
        NgProgressModule,
        NgxSpinnerModule,
        NgbModule,
        NgPipesModule
    ],
    declarations: [AppComponent,
       ModalComponent, DeviceNewProfileProcedureComponent, DeviceNewGroupProcedureComponent, LoginComponent],
    providers: [
      AuthGuard,
      DeviceService,
      UtilService, SharedService, DeviceProfileService, DeviceGroupService, LoadingSpinnerService],
    bootstrap: [AppComponent]
})
export class AppModule {}
