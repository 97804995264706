import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../router.animations';
import { CommonURL } from '../commons/commons';
import { RequestOptions, Headers } from '@angular/http';
import { UtilService } from '../util/utility.services';
import { Login } from '../commons/classes/login';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [routerTransition()]
})
export class LoginComponent implements OnInit {
  error;
  inCorrectCredentials = false;
    constructor(
      private router: Router,
      private util: UtilService
    ) {}
    result: any = {};
    ngOnInit() {}

    onLoggedin() {
        localStorage.setItem('isLoggedin', 'true');
    }

    login(loginDetails) {

    const login = Login.create(loginDetails.username, loginDetails.password);

    // console.log(deviceDetailClass);
    const body = JSON.stringify(login);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_LOGIN, body)
    .subscribe(
      (response) => {
        // console.log('Response: ', response);
        this.result.id = response.id;
        this.result.token = response.token;
        this.result.role_id = response.role_id;
        this.result.org_id = response.org_id;
        // console.log(this.result);
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_KEY, JSON.stringify(this.result));
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());
        // localStorage.setItem('taglock_user', JSON.stringify(response))
        // console.log(response.status);
        if ( response.status === 200 ) {
        this.router.navigate(['/tables']);
        }
      }, (error) => {
        if (error.status === 401) {
          this.result = error;
          this.inCorrectCredentials = true;
          console.log(this.inCorrectCredentials);
        }
        console.log(error);
      }
    );


  }
}

