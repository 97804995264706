import { Injectable, } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Http, Headers, RequestOptions, URLSearchParams, Response } from '@angular/http';
import {UtilService} from '../util/utility.services'
import {CommonURL} from '../commons/commons'
import { deviceDetails } from '../layout/tables/device';
import { Observable,throwError  } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Options } from 'selenium-webdriver/edge';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  authToken: any;
  constructor(private http: HttpClient, private util : UtilService) { }

  getDeviceDetails(){
    var apiurl = "http://127.0.0.1/taglock-api/device";

    let headers = new Headers({  'Content-Type': 'application/x-www-form-urlencoded','authorization': '$1$Lj..2n1.$HuvAKmgdBNJPgBa4h2FES/', 'user-id': '1' });
    let options = new RequestOptions({ headers: headers });

    let body = JSON.stringify(null);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_DEVICES, body, options).subscribe(
      (response) => {
        return response;
      }, (error) => {
        return error;
      }
    );
    }

    getDevice(deviceiId){
       return this.http.get("http://127.0.0.1/taglock-api/device/detail" + deviceiId)  ;
    }
}
