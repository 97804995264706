export class Login{
  username: string;
  password: string;

  static create(username, password){
    return new Login(username, password);
  }

  constructor(username, password){
    this.username = username;
    this.password = password;
  }
}
