import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DeviceGroupService } from '../service/device-group.service';
import { CommonURL } from '../commons/commons';
import { RequestOptions, Headers } from '@angular/http';
import { UtilService } from '../util/utility.services';
import { PostGroupDetails } from '../commons/classes/post_group_details';
import { Router } from '@angular/router';

declare var $; // declared a var just for modal

@Component({
  selector: 'app-device-new-group-procedure',
  templateUrl: './device-new-group-procedure.component.html',
  styleUrls: ['./device-new-group-procedure.component.scss']
})
export class DeviceNewGroupProcedureComponent implements OnInit {
    // Viewchild is used for modal popup message when getting response from the server
    @ViewChild('successmodal') successmodal: ElementRef;
    @ViewChild('failmodal') failmodal: ElementRef;

  profileDetails$
  groupDataReceived;
  radio:any;
  userObject:any = {};
  authDetails;
  constructor(private deviceGroupService: DeviceGroupService, private util: UtilService, private router: Router) { }

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        id: this.userObject.id,
        token: this.userObject.token
      };
    this.deviceGroupService.currentData.subscribe(response => this.groupDataReceived = response);
    console.log(this.groupDataReceived);
// to get all the device info
    this.getDeviceDetails();
    }
  }


  getDeviceDetails(){
    let headers = new Headers({
      'authorization': this.authDetails.token,
      'user-id': this.authDetails.id,
       });
    let options = new RequestOptions({ headers: headers});

    let body = JSON.stringify(null);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_PROFILE_LIST, body, options).subscribe(
      (response) => {
        // console.log(response);
        this.profileDetails$ = response;
      }, (error) => {
        console.log(error)
      }
    );
    }

    save(){
      let profile_id = this.radio;
      console.log(this.radio);

      let headers = new Headers({
        'authorization': this.authDetails.token,
        'user-id': this.authDetails.id,
        }
        );
      let options = new RequestOptions({ headers: headers });

      let groupDetails =  PostGroupDetails.create(this.groupDataReceived.group_name, this.groupDataReceived.group_description, profile_id);
      let body = JSON.stringify(groupDetails);
      // console.log(body);

      this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GROUP_PROCEDURE, body, options)
      .subscribe(
        (response) => {
          // console.log('Response: ', response);
          $(this.successmodal.nativeElement).modal('show');
        }, (error) => {
          console.log(error);
          $(this.failmodal.nativeElement).modal('show');
        }
      );
      // this.router.navigate(["/deviceGroups"]);

    }
}
