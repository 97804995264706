import { Injectable } from '@angular/core';
// import {MatDialog, MatDialogRef} from '@angular/material';
import { Http, URLSearchParams, Response } from '@angular/http';
import { RequestOptions } from '@angular/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { CommonURL } from '../commons/commons';
import { SharedService } from '../util/shared-service.services';
// import {DialogResultCustomDialog} from "../dialogs/custom-dialog/custom-dialog-component";
import {CommonUIStrings} from '../commons/commonUIStrings';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  mutex = false;
  dialogRef: any;
  constructor(private http: Http, private router: Router, private sharedService: SharedService) {
    this.mutex = false;
   }


   callGetApi(apiurl: string, params?: URLSearchParams) {
    return this.http.get(apiurl, {
        search: params
      })
      .pipe( map(
        (response: Response) => {
          return this.handleResponse(response, apiurl);
        }
      )
      , catchError(
        // (error: Response) => {
        //  return this.handleErrorResponse(error.json());
        // }
        (error: Response) => (error.status === 401) ? this.handleErrorResponse(error.json()) : Observable.throw(error.json())
      ));
  }

  callPostApi(apiurl: string, body: any, options?) {

    if (this.isInValidSession()) {
      this.redirectToLogin();
    } else {
      return this.http.post(apiurl, body, options).pipe( map(
        (response: Response) => {
          // if(apiurl == Commons.BASE_URL + Commons.GET_VENUE_PERK_REPORT_EXCEL)
          //   return this.handleResponse(response, apiurl);
          // else
          const json = response.json() as any;
          // console.log(response);
          // if (!json.is_success) {
            // console.log('Value expected!');
            // throw new Error(json);
          // }
            return this.handleResponse(response.json(), apiurl);
        }
      ),
      catchError(
        (error: Response) => ((error.status === 401 || error.status === 404) && apiurl !== (CommonURL.BASE_URL + CommonURL.URL_LOGIN)) ? this.handleErrorResponse(error.json()) : Observable.throw(error.json())

      ));
    }

  }

  callPutApi(apiurl: string, body: any, params?: URLSearchParams) {
    return this.http.post(apiurl, body, {
      search: params
    }).pipe(map(
      (response: Response) => {
        return this.handleResponse(response.json(), apiurl);
      }
    ), catchError(
      // (error: Response) => Observable.throw(error.json().error
      //  || "Server Error")
      (error: Response) => (error.status === 401) ? this.handleErrorResponse(error.json()) : Observable.throw(error.json())

      // (error: Response) => {
      //  return this.handleErrorResponse(error.json())
      // }
    ));
  }

  callDeleteApi(apiurl: string, options?) {
    return this.http.delete(apiurl, options).pipe(map(
      (response: Response) => {
        return this.handleResponse(response.json(), apiurl);
      }
    ), catchError(
      // (error: Response) => Observable.throw(error.json().error
      //  || "Server Error")
      //
      (error: Response) => (error.status === 401) ? this.handleErrorResponse(error.json()) : Observable.throw(error.json())
      // (error: Response) => {
      //  return this.handleErrorResponse(error.json())
      // }
    ));
  }

  prepareRequestParams(params): URLSearchParams {
    const searchParam: URLSearchParams = new URLSearchParams();
    if (typeof params === 'object') {
      for (const i in params) {
        searchParam.set(i, params[i]);
      }
      return searchParam;
    }
    return null;
  }

  handleResponse(response, apiurl) {
    // if(apiurl == Commons.BASE_URL + Commons.GET_VENUE_PERK_REPORT_EXCEL)
    //   return response;
    // else{
      // if (response.status_code == 200 || response.status_code == 201) {
        return response;
      // } else {
      //   return null;
      // }
    // }
  }

  handleErrorResponse(error) {
    if (error.status_code === 401) {
      // this.sharedService.dialogText = {
      //   title : CommonUIStrings.FAILURE,
      //   description : error.message,
      //   isInfoDialog : true,
      //   headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
      //   headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      // };
      // this.dialogRef = this.dialog.open(DialogResultCustomDialog);
      // this.dialogRef.afterClosed().subscribe(result => {
      //   if(result == 1)
      //   {
      //     //window.history.back();
          this.router.navigate(['/' + CommonURL.URL_LOGIN ]);
      //   }
      // });
      // this.myDialog.hidePleaseWait();
    } else if (error.status_code === 403) {
      console.log('error 403');
      // this.sharedService.dialogText = {
      //   title : CommonUIStrings.FAILURE,
      //   description : error.message,
      //   isInfoDialog : true,
      //   headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
      //   headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      // };
      // this.dialogRef = this.dialog.open(DialogResultCustomDialog);
      // this.dialogRef.afterClosed().subscribe(result => {
      //   if(result == 1)
      //   {
      //     //window.history.back();
      //     //this.router.navigate(["/" + Commons.URL_LOGIN ]);
      //   }
      // });
    } else {
      return error;
    }


  }

  setLocalstorageKey(key, val) {
    localStorage.setItem(key, val);
  }

  getLocalStorageKey(key) {
    return localStorage.getItem(key);
  }

  removeLocalStorageKey(key) {
    localStorage.removeItem(key);
  }

  isInValidSession() {

    if (this.getLocalStorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP) == null &&
    this.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY) == null &&
    this.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST) == null) {
      return false;
    } else {
      const lastTimeStamp: any = JSON.parse(this.getLocalStorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP));

      const currentTimeStamp = Date.now();

      // console.log("currentTimeStamp - lastTimeStamp",currentTimeStamp - lastTimeStamp)
      // console.log("CommonURL.SESSION_TIMEOUT",12 * 60 * 60 * 1000)
      if ((currentTimeStamp - lastTimeStamp) > CommonURL.SESSION_TIMEOUT) {
        this.removeLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY);
        this.removeLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST);
        this.removeLocalStorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP);
        return true;
      } else {
        return false;
      }
    }
  }

  redirectToLogin() {
    this.sharedService.updateIsRequestOn(false);
    this.sharedService.dialogText = {
      title : CommonUIStrings.FAILURE,
      description : CommonUIStrings.SESSION_EXPIRED,
      isInfoDialog : true,
      headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
    };
    // this.dialogRef = this.dialog.open(DialogResultCustomDialog, {
      // "disableClose" : true
    // });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.router.navigate(['/' + CommonURL.URL_LOGIN ]);
      }
    });
  }

  // myDialog = this.myDialog || (function () {
  //   var pleaseWaitDiv = $(`<div class="modal fade"
  //   id="pleaseWaitDialog" data-backdrop="static"
  //   data-keyboard="false"><div class="modal-dialog"><div
  //     class="modal-content"><div
  //     class="modal-header"><h4>Processing...</h4></div><div
  //     class="modal-body"><div class="progress"><div class="progress-bar
  //   progress-bar-striped active" role="progressbar" aria-valuenow="100"
  //   aria-valuemin="0" aria-valuemax="100"
  //   style="width:100%"></div></div></div></div></div></div>`);
  //   return {
  //     showPleaseWait: function () {
  //       pleaseWaitDiv.modal('show');
  //     },
  //     hidePleaseWait: function () {
  //       pleaseWaitDiv.modal('hide');
  //     },

  //   };
  // })();
}

