
import {CommonURL} from "./commons";
export class CommonUIStrings {


  static ASSIGN_USER_POPUP_BG_CLASS = 'colored-celebration-header';
  static ASSIGN_USER_POPUP_ICON_CLASS = 'tag-success-popup';

  static BLOCK_POPUP_BG_CLASS = 'colored-block-header';
  static BLOCK_POPUP_ICON_CLASS = 'tag-block-popup';

  static BOOKMARK_POPUP_BG_CLASS = 'colored-bookmark-header';
  static BOOKMARK_POPUP_ICON_CLASS = 'tag-bookmark-popup';

  static CELEBRATION_POPUP_BG_CLASS = 'colored-celebration-header';
  static CELEBRATION_POPUP_ICON_CLASS = 'tag-celebration-popup';

  static CONFIRM_POPUP_BG_CLASS = 'colored-success-header';
  static CONFIRM_POPUP_ICON_CLASS = 'tag-success-popup';

  static REJECT_POPUP_BG_CLASS = 'colored-reject-header';
  static REJECT_POPUP_ICON_CLASS = 'tag-reject-popup';

  static SUCCESS_POPUP_BG_CLASS = 'colored-success-header';
  static SUCCESS_POPUP_ICON_CLASS = 'tag-success-popup';

  static UNAUTHORISED_POPUP_BG_CLASS = 'colored-unauthorised-header';
  static UNAUTHORISED_POPUP_ICON_CLASS = 'tag-unauthorised-popup';

  static SUCCESS:string = 'Success';
  static FAILURE:string = 'Failure';
  static FORBIDDEN:string = 'Forbidden!';
  static REJECTED:string = 'Rejected';
  static UNAUTHORISED:string = 'Unauthorised';
  static BLOCKED:string = 'Blocked';

  static VENUE_ONBOARD_SUCCESS:string = 'Venue was successfully added';
  static VENUE_UPDATE_SUCCESS:string = 'Venue was updated successfully';
  static VENUE_ADD_USER_SUCCESS:string = 'User was added successfully';

  static FEED_APPROVE_TITLE:string = 'Do you want approve this feed?';
  static FEED_APPROVE_DESCRIPTION:string = 'Feed will be moved to approved section.';

  static FEED_REJECT_TITLE:string = 'Do you want to reject this feed?';
  static FEED_REJECT_DESCRIPTION:string = 'Feed will be moved to rejected section.';

  static FEED_BOOKMARK_TITLE:string = 'Do you want to bookmark this feed?';
  static FEED_BOOKMARK_DESCRIPTION:string = 'Feed will be bookmarked.';

  static FEED_CELEBRATION_TITLE:string = 'Do you want to mark this feed as celebration?';
  static FEED_CELEBRATION_DESCRIPTION:string = 'Feed will be marked as celebration.';

  static USER_BLOCK_TITLE:string = 'Do you want to block this user?';
  static USER_BLOCK_DESCRIPTION:string = 'User will be marked as blocked.';

  static REVOKE_ACCESS_TITLE:string = 'Revoke Access';
  static REVOKE_ACCESS_DESCRIPTION:string = 'Do you want revoke access of this user?';

  static FEED_APPROVED_SUCCESS:string = 'Feed was approved successfully';
  static FEED_REJECTED_SUCCESS:string = 'Feed was rejected successfully';
  static FEED_CELEBRATION_SUCCESS:string = 'Feed was marked as celebration successfully';
  static FEED_BOOKMARKED_SUCCESS:string = 'Feed was bookmarked successfully';
  static USER_BLOCKED_SUCCESS:string = 'User was blocked successfully';
  static PERK_UPDATED_SUCCESS:string = 'Perk was updated successfully';
  static VENUE_ADD_SUCCESS:string = 'Venue was added successfully';

  static PERK_UPDATED_ERROR:string = 'Perk was not updated successfully';
  static PERK_DESC_MESSAGE_ERROR:string = 'Please add Description,Message and terms';
  static PERK_TIME_ERROR:string = 'Please enter valid Time. End time must be greater than start time.';
  static ENTER_AT_LEAST_ONE_SESSION:string = 'Please enter at least one session.';
  static TIME_MISSING:string = 'One of the session is missing start time or end time.';
  static PERK_INVALID_ERROR:string = 'Please enter valid details';
  static VENUE_UPDATE_ERROR:string = 'Venue was not updated';
  static VENUE_ADD_ERROR:string = 'Venue was not added';

  static VENUE_NOTE_SUCCESS:string = "Venue note was added successfully";
  static POST_COMMENT_SUCCESS:string = "Successfully replied to comment.";


  static SEND_PERK_SUCCESS:string = 'Perk Sent Successfully!';
  static SEND_PERK_DESCRIPTION:string = 'Perk was sent successfully.';

  static PERK_NOT_SELECTED_MESSAGE:string = "Please select the perk to send";
  static INSTAGRAM_COMMENT_NOT_SENT:string = "Please check the message or please contact admin for this issue.";

  static USER_ALREADY_PRESENT:string = "This user cannot be added as it is already assigned to organization.";

  static LIMIT_USER_SELECT_DIALOG_TEXT:string = 'You can select only ' + CommonURL.LIMIT_USER_SELECTION_COUNT + ' users at a time.';

  static SESSION_EXPIRED:string = "Your Session has expired. Please login again to continue.";

  static USER_BLOCKED:string = "You cannot send perk to this user. This user is blocked.";

  static CONFIRM_REMOVE_MESSAGE:string = 'Do you want revoke access of this account?';
}
