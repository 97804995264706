export class PostGroupDetails{
  group_name: string;
  group_description: string;
  profile_id: number;

  static create(group_name, group_description, profile_id){
    return new PostGroupDetails(group_name, group_description, profile_id);
  }

  constructor(group_name, group_description, profile_id){
    this.group_name = group_name;
    this.group_description = group_description;
    this.profile_id = profile_id;
  }
}
