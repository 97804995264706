
export class CommonURL {

  static LOCAL_STORAGE_USER_KEY = 'taglock_user';
  static LOCAL_STORAGE_DEVICE_KEY = 'device_id';
  static LOCAL_STORAGE_USER_LIST = 'taglock_user_list';
  static LOCAL_STORAGE_SESSION_TIMESTAMP = 'taglock_session_timestamp';

  // prod
  // static BASE_URL = 'https://taglockapi.tagloy.com/';
  static BASE_URL = 'https://telelockapi.tagcx.com/';
  // static BASE_URL = 'https://prelockapi.tagloy.com/';
  // static BASE_URL = 'http://15.206.182.118/';
  // static BASE_URL = 'http://localhost/taglock_data/';


  static VERSION = 0.1;
  static URL_LOGIN = 'auth/login';
  static URL_LOGOUT = 'auth/logout';
  static DEVICE_COMMAND = 'device/command';
  static URL_DEVICES = 'device/detail';
  static URL_DEVICE_LOCATIONS = 'device/devicelocations';
  static URL_DEVICE_DETAIL = 'device/detailid/';
  static URL_DEVICE_DELETE = 'device/deletedevice/';
  static URL_CRED_CLEAR = 'device/clearcred/';
  static URL_PROFILE_PROCEDURE = 'profiles/insertprofile';
  static URL_PROFILE_LIST = 'profiles/profile';
  static URL_APK = 'apk/insertapk';
  static URL_GET_APK = 'apk/apk';
  static URL_WALLPAPER = 'groups/addwallpaper';
  static URL_PUSH_APK_GROUP = 'apk/publishgroup';
  static URL_PUSH_TAGLOCK_GROUP = 'taglock/publishgroup';
  static URL_PUSH_APK_PROFILE = 'apk/publishprofile';
  static URL_GROUP_PROCEDURE = 'groups/insertgroup';
  static URL_GROUP_DETAILS = 'groups/groupdetails';
  static URL_GET_GROUPS = 'groups/group';
  static URL_GET_ORGGROUPS = 'groups/orggroups';
  static URL_GROUP_PROFILE = 'groups/groupprofile';
  static URL_GROUP_DETAIL_CUSTOM_DATA = 'device/property';
  static URL_GROUP_DETAILS_APK = 'groups/groupapkdetail';
  static URL_GROUP_DETAILS_TAGLOCK = 'groups/grouptagdetail';
  static URL_GROUP_DEVICE_BY_ID = 'groups/groupdevice/';
  static URL_INSERT_ORGANIZATION = 'organizations/insertorg';
  static URL_GET_ORGANIZATIONS = 'organizations/getorgs';
  static URL_GET_GROUP_ORGANIZATIONS = 'organizations/getgrouporgs';
  static URL_GET_ORGANIZATION_BY_ID = 'organizations/getorg';
  static URL_UPDATE_ORGANIZATION = 'organizations/editorg';
  static URL_DELETE_ORGANIZATION = 'organizations/deleteorg';
  static URL_INSERT_CREDENTIALS = 'credentials/insertcred';
  static URL_UPDATE_CREDENTIALS = 'credentials/updatecred';
  static URL_GET_CREDENTIALS = 'credentials/getcred';
  static URL_INSERT_USER = 'users/insertuser';
  static URL_GET_USERS = 'users/getusers';
  static URL_GET_USERS_ORGS = 'users/getuserorgs';
  static URL_GET_USER_BY_ID = 'users/getuser';
  static URL_UPDATE_USER = 'users/edituser';
  static URL_DELETE_USER = 'users/deleteuser';
  static URL_GET_ROLES = 'roles/getroles';
  static URL_APK_BY_ID = 'apk/apkid/';
  static URL_TAGLOCK_APK = 'taglock/taglock/';
  static URL_TAGLOCK_APK_BY_ID = 'taglock/taglockid/';
  static URL_TAGLOCK_APK_COUNT = 'taglock/installcount';
  static URL_APP_APK_COUNT = 'apk/installcount';
  static URL_DEVICE_UPDATE = 'device/updatedevice';
  static URL_PROFILE_UPDATE = 'profiles/updateprofile';
  static URL_WALLPAPER_PATH = 'upload/wallpaper/';

  static LIMIT_USER_SELECTION_COUNT = 30;
  static SESSION_TIMEOUT: number = 12 * 60 * 60 * 1000;
  // static SESSION_TIMEOUT:number = 30 * 1000;
}
