import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceGroupService {

  constructor() { }

  private createDeviceGroupDetails = new BehaviorSubject("");
  currentData = this.createDeviceGroupDetails.asObservable();

  PassedData(message){
    this.createDeviceGroupDetails.next(message);
  }
}
